<template>
  <div>
    <div>
      <v-simple-table class="table-padding-2-no-top">
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">
              {{ $t("labels.apply") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.spend") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.accumulate_point") }}
            </th>
            <th role="columnheader" class="text-center">
              <v-btn
                x-small
                color="purple"
                class="white--text"
                @click="showBuyerDialog"
              >
                {{ $t("labels.check_point") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center">
            <td>
              <span class="font-weight-medium"> {{ $t("labels.all") }} </span>
            </td>
            <td>
              <input-number
                v-model="config.spend"
                :disabled="!editing"
                outlined
                dense
                hide-details
                type="number"
                class="c-input-small"
              />
            </td>
            <td>
              <input-number
                v-model="config.point"
                :disabled="!editing"
                outlined
                dense
                hide-details
                type="number"
                class="c-input-small"
              />
            </td>
            <td>
              <template v-if="!editing">
                <v-btn
                  class="ma-1"
                  x-small
                  color="warning"
                  @click="toggleEditing(true)"
                  >{{ $t("labels.edit") }}</v-btn
                >
                <v-btn
                  class="ma-1"
                  x-small
                  color="primary"
                  @click="showHistoryDialog"
                >
                  {{ $t("labels.history") }}
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                  class="ma-1"
                  x-small
                  color="success"
                  @click="updateConfig"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <v-btn
                  class="ma-1"
                  x-small
                  color="error"
                  @click="toggleEditing(false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>

    <div class="mt-3">
      <div class="text-h6">
        {{ $t("labels.accrue_point_program") }}
      </div>
      <AccruePointProgram />
    </div>

    <v-dialog v-model="historyDialog" max-width="480px">
      <AccruePointHistory v-if="historyDialog" @close="closeHistoryDialog" />
    </v-dialog>

    <v-dialog v-model="buyerDialog" max-width="1024px">
      <AccruePointBuyer v-if="buyerDialog" @close="closeBuyerDialog" />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "AccruePointConfig",
  components: {
    AccruePointHistory: () => import("@/components/pos/AccruePointHistory.vue"),
    AccruePointBuyer: () => import("@/components/pos/AccruePointBuyer.vue"),
    AccruePointProgram: () => import("@/components/pos/AccruePointProgram.vue"),
  },
  data: () => ({
    config: {},
    editing: false,
    isLoading: false,
    historyDialog: false,
    buyerDialog: false,
    moneyOptions: {
      length: 11,
      precision: 0,
    },
  }),
  mounted() {
    this.getConfig();
  },
  methods: {
    getConfig() {
      httpClient.post("/customer-accrue-point").then(({ data }) => {
        this.config = { ...data };
      });
    },
    toggleEditing(editing) {
      this.editing = editing;
    },
    showHistoryDialog() {
      this.historyDialog = true;
    },
    closeHistoryDialog() {
      this.historyDialog = false;
    },
    showBuyerDialog() {
      this.buyerDialog = true;
    },
    closeBuyerDialog() {
      this.buyerDialog = false;
    },
    async updateConfig() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/customer-accrue-point-save", this.config);
        this.isLoading = false;
        this.editing = false;
        this.$vToastify.success(this.$t("messages.update_success"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
